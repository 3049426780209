<template>
    
    <div class="h-full w-full flex flex-col justify-center items-center">

        <div class="flex flex-col justify-center items-center" :class="{'h-full': mobile, 'h-72': !mobile}">

            <span class=" text-aux font-semibold" :class="{'text-sm': mobile, 'text-5xl mb-2': !mobile}">LOADING SETTINGS</span>
            <div class="h-32 relative flex flex-col justify-center items-center">
                <loader :loading="true"></loader>
            </div>

        </div>

    </div>

</template>

<script>

import { state } from '@/store';

export default {
    
    data(){
        return{
            token:false,
            email:false
        }
    }, 
    computed:{
       mobile(){
           if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
               return true
           } else{
               return false
           }
       }
    },
    mounted(){

        if(state.token){

            setTimeout(() => {

                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){

                    if(state.user.screen != 3){
                        this.$router.push( {name:'homeMobile'} )
                    }

                    if(state.user.screen == 3){
                        this.$router.push( {name:'HomePersonalDashboard'} )
                    }

                } else {

                    
                    if(state.user.screen != 3){
                        this.$router.push( {name:'desktop'} )
                    }

                    if(state.user.screen == 3){
                        this.$router.push( {name:'PersonalDashboard'} )
                    }


                }

                
            }, 2000);

        } else {

            this.$router.push( {name:'login'} )

        }
    }

}
</script>